<template>
  <!-- 登录背景 -->
   <div
    class="login-back"
   :style="{
      background:'url(' + require('@/assets/images/testlogin_back.gif') + ')'+'no-repeat'
    }"
  >
    <!-- <img class="logo" src="@/assets/images/login-logo.png" alt="" /> -->
    <img class="logo_zhineng" src="@/assets/images/zhineng_logo.png" alt="" />
    <!-- 登录容器 -->
    <div class="login_outer">
      <div class="title">智能化多媒体管理和生产系统</div>
      <div class="login-container">
        <!-- 登录标题-->
        <div class="login-or-register">登录</div>
        <!-- 登录，注册表单 -->
        <el-form
          :model="dynamicValidateForm"
          ref="dynamicValidateForm"
          class="demo-dynamic"
          :rules="rules"
        >
          <!-- 邮箱 -->
          <el-form-item prop="email" :error="error1" show-message>
            <el-input
              @keyup.enter.native="submitForm('dynamicValidateForm')"
              v-model="dynamicValidateForm.email"
              placeholder="邮 箱"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password" :error="error2" show-message>
            <el-input
              @keyup.enter.native="submitForm('dynamicValidateForm')"
              type="password"
              v-model="dynamicValidateForm.password"
              placeholder="密 码"
            ></el-input>
          </el-form-item>
          <!-- 登录按钮-->
          <el-form-item>
            <el-button
              class="test"
              style=" width: 20.21vw;height: 4.3vh;"
              type="primary"
              @click="submitForm('dynamicValidateForm')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
        <!-- 忘记密码，没有账号 ?立即注册 -->
        <div class="bottom">
          <!-- 忘记密码 -->
          <div>
            <router-link
              style="text-decoration: none; cursor: pointer"
              tag="a"
              target="_blank"
              :to="{ name: 'FindPassword' }"
              >忘记密码</router-link
            >
          </div>
          <!-- 没有账号？立即注册 -->
          <div @click="goRegister" style="cursor: pointer">
            没有账号 ?立即注册
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
//登录背景
.login-back {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
   position:fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-size: cover !important;
  // 登录背景logo
  .logo {
    width: 11.5vw;
    height: 10.2vh;
    margin-top: 12vh;
    margin-right: 20.5vw;
  }
  .logo_zhineng{
    width: 13vw;
    margin: 12vh 19.5vw 1vh auto;
  }
  .login_outer{
    margin-right: 9.9vw;
  }
  // 标题
  .title {
    font-size: 1.6vw;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 4px;
    text-align: center;
  }
  // 登录容器
  .login-container {
    margin-top: 6.9vh;
    width: 32.85vw;
    height: 47.6vh;
    background: rgba(57, 56, 70, 0.6);
    border-radius: 5px;
    // 登录 注册切换
    .login-or-register {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      margin-left: 6.31vw;
      padding-top: 8.76vh;
    }
    // 登录，注册表单
    .demo-dynamic {
      margin-top: 2.14vh;
      width: 20.21vw;
      margin-left: 6.1vw;
    }
    /deep/.el-input__inner {
      background: #393846;
      border-radius: 5px;
      opacity: 0.6;
      color: #fff;
    }
    /deep/.el-form-item__error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 30%;
      left: auto;
      right: -6vw;
      width: 5.9vw;
    }
    .test{
      /deep/span{
          padding-left:50px;
        letter-spacing: 50px;
      }
    }
    // 忘记密码，没有账号 ?立即注册
    .bottom {
      display: flex;
      width: 20.21vw;
      height: 4.3vh;
      margin-left: 6.3vw;
      justify-content: space-between;
    }
  }
}

</style>
<script>
import md5 from 'js-md5';
export default {
  name: 'Login',
  data() {
    return {
      // 表单数据对象
      dynamicValidateForm: {
        // 邮箱
        email: '', //"taiyang.yin@maixunbytes.com",
        // 密码
        password: '', //"y666666",
      },
      // 表单验证对象
      rules: {
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '请填写正确邮箱',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            min: 6,
            message: '密码6位以上',
            trigger: 'blur',
          },
        ],
      },
      // 表单错误信息
      error1: '',
      error2: '',
      // 忘记密码
      forgettext: '忘记密码',
    };
  },
  methods: {
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // 通过验证发起登录请求
        if (valid) {
          let formData = new FormData();
          formData.append("email", this.dynamicValidateForm.email);
          formData.append("password", md5(this.dynamicValidateForm.password));
          this.$http({
            method: "POST",
            url: "accounts/login/",
            data: formData,
          }).then((res) => {
            if (res.data.status == 0) {
              // 如果未邮箱验证过的账号登录
              if (res.data.msg == "请前往邮箱激活账号") {
                this.error1 = res.data.msg;
                this.$http({
                  method: "POST",
                  url: "accounts/register_user",
                  data: formData,
                }).then((res) => {
                  if (res.data.status == 0) {
                    // 将邮箱跟密码做一下缓存，方便ValidateEmail页面进行使用
                    sessionStorage.setItem('Email',this.dynamicValidateForm.email)
                    sessionStorage.setItem('PassWord',this.dynamicValidateForm.password)
                    // 跳转邮箱验证界面
                    this.$router.push({
                      path: "/validatemail"
                    });
                  }
                }).catch(error => console.log(error));

                // 登录成功
              } else {
                // 将邮箱跟品牌,还有部门跟职位,姓名做数据持久化
                localStorage.setItem("email", res.data.data.email);
                localStorage.setItem(
                  "company_name",
                  res.data.data.company_name
                );
                this.$store.commit("setEmail",res.data.data.email)
                this.$store.commit("setBrand",res.data.data.company_name)

                // 获取用户信息是否完善状态
                this.getUserStatus();
              }
            }
            // 登录失败
            else if (res.data.status == 1) {
              this.error1=''
              this.error2=''
              if (res.data.msg == "账号不存在") {
                this.$nextTick(()=>{
                    this.error1 = "账号不存在";
                })
              }
              if (res.data.msg == "该邮箱没有权限") {
                this.$nextTick(()=>{
                    this.error1 = "该邮箱没有权限";
                })

              }
              if (res.data.msg == "密码错误") {
                this.$nextTick(()=>{
                  this.error2 = "密码错误";
                })

              }
            }
          }).catch(error => console.log(error));
        } else {
          return false;
        }
      });
    },
    // // 获取用户信息是否完善状态
    getUserStatus() {
      this.$http({
        method: "GET",
        url: "accounts/get_complete_status/",
      }).then((res) => {
        // 信息完善
        if (res.data.status == 0) {
          this.$router.push("/");
        }
        // 信息不完善
        else {
          this.$router.push({
            path: "/userinfo",
          });
        }
      }).catch(error => console.log(error));
    },
    // 跳转注册界面
    goRegister() {
      this.$router.push("/register");
    },
  },
};
</script>
